/** @format */

import React from "react";

const Roadmap = () => {
  return (
    <>
      <div className='container-fluid sec-main pt-5 ' id='roadmap'>
        <div className=' roadmap '>
          <div className='row'>
            <h2>Roadmap</h2>
          </div>
          <div className='row partners mt-4'>
            <div className='partner-container'>
              <h5 className='gradient-text position'>Phase 1:</h5>
              <div className='logo-container space-between'>
                <span
                  data-aos='fade-up'
                  data-aos-delay={300}
                  className='aos-init aos-animate'>
                  <ul>
                    <li>Website launch</li>
                    <li>Social media presence</li>
                    <li>1,000+ Holders</li>
                    <li>
                      Get $XELON Trending on twitter with our memetic power
                    </li>
                  </ul>
                </span>
              </div>
            </div>
            <div className='partner-container'>
              <h5 className='gradient-text position'>Phase 2:</h5>
              <div className='logo-container space-between'>
                <span
                  data-aos='fade-up'
                  data-aos-delay={300}
                  className='aos-init aos-animate'>
                  <ul>
                    <li>
                      Community Partnerships XELON Times digital newsletter
                    </li>
                    <li>
                      Formation of token gated Telegram group, XELON Palace, for
                      holders, more details tba
                    </li>
                    <li>CEX Listings 10,000+holders</li>
                  </ul>
                </span>
              </div>
            </div>

            <div className='partner-container'>
              <h5 className='gradient-text position'>Phase 3:</h5>
              <div className='logo-container space-between'>
                <span
                  data-aos='fade-up'
                  data-aos-delay={300}
                  className='aos-init aos-animate'>
                  <ul>
                    <li>XELON merch</li>
                    <li>XELON Academy</li>
                    <li>XELON Tools</li>
                    <li>T1 Exchange Listings 100,000+ holders</li>
                    <li>Meme Takeover</li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
