/** @format */

import { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavItems from "../components/utils/NavItems";
import OffcanvasSec from "../components/utils/OffcanvasSec";

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='Header-Selection'>
      <Navbar collapseOnSelect expand='lg'>
        <Container>
          <Navbar.Brand href='/'>
            <img
              style={{ marginTop: -10 }}
              src='assets/logo.png'
              width={160}
              alt=''
            />
            {/* <span className='m-auto'> </span>{" "} */}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav'
            onClick={handleShow}
          />
          <Navbar.Collapse
            className='d-none d-lg-block'
            id='responsive-navbar-nav'>
            <NavItems />
            <a href='https://dexscreener.com/solana/2CzqA7bm5NCR5q6ET28ZCwtj8B96haK9uVkUj6SwZbkE' target='_blank' rel='noopener noreferrer'>
              {" "}
              <button type='button' class='btn gap-2 btn-social2'>
                <img
                  className='mx-1'
                  src='assets/ds.png'
                  style={{ width: 25, display: "inline-block" }}
                  alt=''
                />{" "}
                Chart
              </button>
            </a>
          </Navbar.Collapse>
        </Container>
        <OffcanvasSec handleClose={handleClose} show={show} />
      </Navbar>
    </div>
  );
}

export default Header;
