/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <div className='Tokenomics-section py-5'>
      <div className='container'>
        <h2 className='text-center' data-aos='zoom-in-left'>
          TOKENOMICS
        </h2>
      

        <div className='card-sec mt-5'>
          <div className='row'>
            <div className='col-md-3 mt-3 mt-md-0'>
              <div
                className='bg-card p-3'
                data-aos='fade-up'
                data-aos-duration='2000'>
                <h4>TAX</h4>

                <h1>0/0</h1>
              </div>
            </div>
            <div className='col-md-3 mt-3 mt-md-0'>
              <div
                className='bg-card p-3'
                data-aos='fade-up'
                data-aos-duration='2000'>
                <h4>liquidity lock</h4>

                <h1>BURNED</h1>
              </div>
            </div>
            <div className='col-md-3 mt-3 mt-md-0'>
              <div
                className='bg-card p-3'
                data-aos='fade-up'
                data-aos-duration='2000'>
                <h4>CONTRACT</h4>

                <h1>RENOUCNED</h1>
              </div>
            </div>
            <div className='col-md-3 mt-3 mt-md-0'>
              <div
                className='bg-card p-3'
                data-aos='fade-up'
                data-aos-duration='2000'>
                <h4>TOTAL SUPPLY</h4>

                <h1>420,690,000,000,000</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
