/** @format */

import React from "react";
import Hero from "../../components/elements/home/Hero";
import About from "../../components/elements/home/About";
import Tokenomics from "../../components/elements/home/Tokenomics";
import Doge from "../../components/elements/home/Doge";
import Partner from "../../components/elements/home/Partner";
import Roadmap from "../../components/elements/home/Roadmap";

const index = () => {
  return (
    <>
      <Hero />
      <Partner />
      {/* <About /> */}
      <Tokenomics />
      <Roadmap />
      {/* <Doge /> */}
    </>
  );
};

export default index;
