/** @format */

import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

const NavItems = ({ handleClose }) => {
  return (
    <>
      <Nav className='ms-auto'>
        <a
          onClick={handleClose}
          className='nav-link'
          href='https://t.me/XElonVip'>
          Telegram
        </a>
        <a
          onClick={handleClose}
          className='nav-link'
          href='https://twitter.com/XElonVip'>
          Twiiter
        </a>
      </Nav>
    </>
  );
};

export default NavItems;
