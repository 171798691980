/** @format */

import React from "react";

const Hero = () => {
  return (
    <div className='hero-section sec-bg2 ' id='hero'>
      <section class='video-container sec-bg2-fixed--height'>
        <div class='content text-white py-5 '>
          <div className='container'>
            <div className='row align-items-center  m-auto '>
              <div className='col-md-6 mb-md-0 mb-5 m-auto '>
                <h1 data-aos='fade-down-right'>
                  Embracing Financial Freedom <br /> through Elon Musk’s
                  X-Factor
                </h1>
                <h5 data-aos='fade-up'>
                  Embracing Financial Freedom through Elon Musk’s X-Factor In
                  the world of cryptocurrencies, where innovation and
                  imagination converge, the $X Project stands out as a promising
                  opportunity for crypto investors seeking to achieve financial
                  freedom.{" "}
                </h5>

                <div
                  className='btn-sec d-flex flex-grow-0 flex-wrap  gap-5 mt-5 align-items-center '
                  data-aos='fade-left'>
                  {/* <a href='' target='_blank' rel='noopener noreferrer'>
                    {" "}
                    <button type='button' class='btn btn-social'>
                      Buy X-Elon
                    </button>
                  </a> */}
                  <a
                    href='https://dexscreener.com/solana/2CzqA7bm5NCR5q6ET28ZCwtj8B96haK9uVkUj6SwZbkE'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <button type='button' class='btn btn-social'>
                      <img
                        className='mx-1'
                        src='assets/ds.png'
                        style={{ width: 25, display: "inline-block" }}
                        alt=''
                      />{" "}
                      Chart
                    </button>
                  </a>
                </div>
              </div>
              <div className='col-md-5 '>
                <div className=' h-50  w-100 d-block'></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
