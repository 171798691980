/** @format */

import React from "react";
import { FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='Footer-Section'>
      <div className='container'>
        <div className='copyright py-3 text-center'>
          <img src='assets/logo-cr.png' className=' w-25 ' alt='' />
          <p className='mb-0'>© Copyright 2024. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
  