/** @format */

import React from "react";

const Partner = () => {
  return (
    <div className='container mt-5 mb-5'>
      <div className='row text-center'>
        <div className='col-sm-3 col-6'>
          <a href='http://' target='_blank' rel='noopener noreferrer'>
            <img src='assets/solscan.svg' className='w-75' alt='' />
          </a>
        </div>
        <div className='col-sm-3 col-6'>
          <a href='http://' target='_blank' rel='noopener noreferrer'>
            <img src='assets/ray.svg' className='w-75' alt='' />
          </a>
        </div>
        <div className='col-sm-3 col-6'>
          <a href='http://' target='_blank' rel='noopener noreferrer'>
            <img
              src='assets/dextools_logo_full_dark.svg'
              className='w-75'
              alt=''
            />
          </a>
        </div>
        <div className='col-sm-3 col-6'>
          <a
            href='https://t.me/XElonVip'
            target='_blank'
            rel='noopener noreferrer'>
            <img src='assets/telegram.png' className='w-75' alt='' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Partner;
